<template>
  <ion-page class="ion-page">
    <GoBackHeader :default-href="`/facilities/${id}`" />

    <ion-content>
      <Refresher @refresh="doRefresh($event, 'getUpcomingFacilityEvents')" />
      <List
        :header="$t('allupcomingevents')"
        :not-found-message="$t('noupcomingeventslabel')"
        :loading="$apollo.loading && !getUpcomingFacilityEvents"
        :loading-more="loadingMore"
        @loadMore="loadMore($event, 'getUpcomingFacilityEvents')"
      >
        <template v-slot:top>
          <UpcomingEventSelectionFilter @change="changeFilter" default-filter-by="ALL" />
        </template>

        <template v-if="getUpcomingFacilityEvents && getUpcomingFacilityEvents.data && getUpcomingFacilityEvents.data.length">
          <div v-for="event in getUpcomingFacilityEvents.data" :key="event.id" @click="openSingleEvent(event.id)">
            <EventItem :key="event.id" :event="event" button />
          </div>
        </template>
      </List>
    </ion-content>
  </ion-page>
</template>

<script>
import GoBackHeader from '@/components/navigation/GoBackHeader';
import List from '@/components/List';
import { GET_UPCOMING_FACILITY_EVENTS } from '@/graphql/queries';
import { infiniteScrollMixin } from '@/mixins';
import { defaultPaginationConfig } from '@/configs';
import EventItem from '@/components/items/EventItem';
import UpcomingEventSelectionFilter from "@/components/items/UpcomingEventSelectionFilter";

export default {
  components: {
    GoBackHeader,
    List,
    EventItem,
    UpcomingEventSelectionFilter
  },
  mixins: [infiniteScrollMixin],
  props: [
    'id'
  ],
  data() {
    return {
      filterBy: 'ALL',
    }
  },
  methods: {
    openSingleEvent(id) {
      this.$router.push({ name: this.$routeNames.EVENT, params: { id }})
    },
    changeFilter(filterOption) {
      this.filterBy = filterOption.key
      this.resetInfiniteScroll();
    },
    async doRefresh(event, model) {
      this.resetInfiniteScroll();
      await this.$apollo.queries[model].refetch();
      event.target.complete()
    }
  },
  apollo: {
    getUpcomingFacilityEvents: {
      query: GET_UPCOMING_FACILITY_EVENTS,
      variables() {
        return {
          ...defaultPaginationConfig,
          facility_id: this.id,
          filter_by: this.filterBy,
        }
      },
    },

  }
}
</script>
